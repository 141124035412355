<script lang="ts" setup generic="T">
import type { KeenSliderOptions } from "keen-slider";

const props = withDefaults(
	defineProps<{
		title: string;
		navigation?: boolean;
		list: T[];
		autoplay?: boolean;
		loop?: boolean;
		isComments?: boolean;
		spacing?: number;
	}>(),
	{
		navigation: false,
		autoplay: false,
		loop: false,
		isComments: false,
		spacing: 12
	}
);

const sliderActiveSlide = ref(0);

const isLast = ref(false);

const sliderOptions: KeenSliderOptions = {
	loop: props.loop,
	mode: "snap",
	breakpoints: {
		"(max-width: 767px)": {
			slides: {
				perView: 1,
				spacing: 0
			}
		}
	},
	slides: { perView: "auto", spacing: props.spacing },
	created(slider) {
		sliderActiveSlide.value = slider.track.details.abs;
	},
	slideChanged(slider) {
		sliderActiveSlide.value = slider.track.details.abs;
	},
	dragged(slider) {
		isLast.value = slider.track.details.slides[props.list.length - 1].portion >= 0.98;
	},
	detailsChanged(slider) {
		isLast.value = slider.track.details.slides[props.list.length - 1].portion >= 0.98;
	}
};

const sliderRef = ref();

const interval = ref();
onMounted(() => {
	if (!props.autoplay) {
		return;
	}
	interval.value = setInterval(() => {
		sliderRef.value.slider.prev();
	}, 2500);
});

onBeforeUnmount(() => {
	clearInterval(interval.value);
});

const { list } = toRefs(props);

watch(
	() => list.value,
	() => {
		nextTick(() => {
			sliderRef.value?.slider?.update();
		});
	}
);
</script>

<template>
	<div class="wrapper">
		<div class="header">
			<AText variant="tianshui" class="text-cannes" :modifiers="['bold']">
				{{ title }}
			</AText>
			<div v-if="navigation" class="nav-container">
				<div @click="sliderRef.slider?.prev()">
					<NuxtIcon
						class="swiper-button"
						:class="{ 'swiper-button-disabled': !loop && sliderActiveSlide === 0 }"
						name="16/right-slider"
					/>
				</div>
				<div @click="sliderRef.slider?.next()">
					<NuxtIcon
						class="swiper-button"
						:class="{ 'swiper-button-disabled': !loop && isLast }"
						name="16/left-slider"
					/>
				</div>
			</div>
			<slot v-else name="viewAll" />
		</div>
		<client-only>
			<ASlider ref="sliderRef" :options="sliderOptions">
				<div class="cards-feed__container">
					<div v-for="(item, index) in list" :key="index" class="">
						<slot :item="item" :index="index" />
					</div>
				</div>
			</ASlider>
		</client-only>
	</div>
</template>

<style lang="scss" scoped>
.flip-list-move {
	transition: transform 0.8s ease;
}
.header {
	max-width: 480px;
	z-index: 20;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	line-height: 1.14;
	&-title {
		color: var(--cannes);
	}
}

.cards-feed {
	$self: &;
	margin-top: gutter(6);
	position: relative;
	z-index: 5;

	@include media-breakpoint-down(md) {
		width: calc(100% + 15px);
		margin-top: gutter(5);
	}
	&__container {
		display: flex;
		position: relative;
		padding-top: 30px;

		@include media-breakpoint-down(md) {
			flex-direction: column;
			gap: 16px;
		}
	}
	&__item {
		height: auto;
		flex-shrink: 0;
		z-index: 3;
		position: relative;
		transform: translateX(0);
	}
}

.nav-container {
	display: flex;
	gap: gutter(3);
	@include media-breakpoint-down(md) {
		display: none;
	}
	.swiper-button {
		color: var(--changchun);
		cursor: pointer;

		&-disabled {
			opacity: 0.25;
		}
	}
}
</style>
